import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FaChevronDown } from "react-icons/fa";

import Text from "components/common/Text";

const FaqItem = ({ className, title, children, onClick, isActive }) => {
  const tab = useRef(null);
  const [init, setInit] = useState(false);
  const [initTabHeight, setInitTabHeight] = useState(0);

  useEffect(() => {
    if (!init) {
      setInitTabHeight(tab.current.offsetHeight + 25);
      setInit(true);
    }
  }, [tab, init]);

  return (
    <div className={classNames("Faq__Item", className)}>
      <button
        onClick={onClick}
        className={classNames(
          "flex flex-row gap-4 items-start py-4 md:py-12 w-full",
          {
            "border-b border-gray border-opacity-20 ": !isActive,
          }
        )}
      >
        <div className="w-full text-left">
          <Text color={isActive ? "white" : "white"} size="lg">
            {title}
          </Text>
        </div>
        <div className="p-1">
          <FaChevronDown
            className={classNames("transition-transform", {
              "rotate-180 text-white": isActive,
              "text-white": !isActive,
            })}
            size={14}
          />
        </div>
      </button>

      <div
        ref={tab}
        style={{
          ...(init && { height: isActive ? `${initTabHeight}px` : "0px" }),
        }}
        className={classNames("overflow-hidden transition-all", {
          "pt-6": init && isActive,
          "border-b border-gray border-opacity-20 ": isActive,
        })}
      >
        {children}
      </div>
    </div>
  );
};

FaqItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  isActive: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

FaqItem.defaultProps = {
  className: "",
  title: "Question",
  isActive: false,
  children: "Answer",
  onClick: () => {},
};

export default FaqItem;
