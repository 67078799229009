import Text from "components/common/Text";
import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Paragraph from "components/common/Paragraph";
import PropTypes from "prop-types";
import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";

const SliderStep = ({
  className,
  width,
  position,
  stepIndex,
  title,
  text,
  reversed,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className="relative h-full min-h-full w-full"
      style={{
        minWidth: `${width}px`,
      }}
    >
      <div
        className={classNames(
          "absolute flex flex-col justify-center items-center",
          {
            "flex-col-reverse": reversed,
          },
          className
        )}
        style={{
          left: position.left,
          bottom: position.bottom,
        }}
      >
        <div className="bg-mine-shaft w-64 px-8 py-12 rounded-xl">
          <div className="flex flex-row items-start gap-3">
            <StaticImage
              className="min-w-4 mt-1"
              src="../../../../images/glyph.png"
              width={20}
              alt="Glyph"
              quality={100}
              placeholder="none"
            />
            <Text
              className="mb-6 font-bold"
              color="white"
              headlineTag="h4"
              size="lg"
            >
              {t(title)}
            </Text>
          </div>

          <Paragraph className="text-sm lg:text-base" color="gray">
            {t(text)}
          </Paragraph>
        </div>
        <div className="h-10 border-nobel border-dashed border-r-2 w-px"></div>
        <div className="border-2 border-jaffa min-h-4 min-w-4 max-h-4 max-w-4 w-4 h-4 rounded-full bg-cod-gray"></div>
      </div>
    </div>
  );
};

SliderStep.propTypes = {
  className: PropTypes.string,
  position: PropTypes.shape({
    left: PropTypes.string,
    bottom: PropTypes.string,
  }).isRequired,
  stepIndex: PropTypes.number.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  reversed: PropTypes.bool,
};

SliderStep.defaultProps = {
  className: "",
  title: "",
  text: "",
  reversed: false,
};

export default SliderStep;
