import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Section from "components/common/Section/Index";
import Text from "components/common/Text";
import RecruitmentProcessSlider from "./RecruitmentProcessSlider/Slider";

const RecruitmentProcessSection = () => {
  const { t } = useTranslation();
  return (
    <Section
      id="recruitment-process"
      className="relative bg-cod-gray round-l-xl round-r-xl overflow-hidden"
    >
      <Section.Container className="m-auto px-4 md:px-2 lg:px-32 pt-14 lg:pt-24 overflow-x-hidden overflow-hidden">
        <Text
          className="mb-6 font-bold"
          color="white"
          headlineTag="h2"
          size="4xl"
        >
          {t("What is the recruitment process like?")}
        </Text>
        <Text
          color="nobel"
          className="block xl:mb-16 font-normal leading-relaxed"
          size="xl"
        >
          {t("See what you can expect.")}
        </Text>
      </Section.Container>
      <RecruitmentProcessSlider
        steps={[
          {
            title: t("Send your CV"),
            text: t("You apply by sending your resume"),
          },
          {
            title: t("IT Recruiter"),
            text: t(
              "A 20-minute conversation with our IT Recruiter about your competencies and expectations"
            ),
          },
          {
            title: t("Technical talk"),
            text: t(
              "30-minute technical talk with a program subject matter expert from CEPD.tech"
            ),
          },
          {
            title: t("Feedback"),
            text: t("You get feedback and (we hope) you compete in the program!"),
          },
        ]}
      />
    </Section>
  );
};

export default RecruitmentProcessSection;
