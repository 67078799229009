import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Section from "components/common/Section/Index";
import Text from "components/common/Text";
import FaqList from "components/common/FaqList";
import Paragraph from "components/common/Paragraph";
import { StaticImage } from "gatsby-plugin-image";

const FaqSection = () => {
  const { t } = useTranslation();
  return (
    <Section
      id="faq"
      className="relative bg-cod-gray round-l-xl round-r-xl overflow-hidden"
    >
      <StaticImage
        width={700}
        style={{ top: 0, left: "15%" }}
        className="absolute"
        src="../../../images/academy-shape-005.svg"
        alt="Leaf"
        quality={100}
      />
      <Section.Container className="m-auto px-12 pt-32 pb-64 overflow-x-hidden overflow-hidden flex flex-col justify-center items-center">
        <Text
          className="mb-24 font-bold"
          color="white"
          headlineTag="h2"
          size="4xl"
        >
          {t("Frequently asked questions")}
        </Text>
        <div className="m-auto lg:w-3/4">
          <FaqList>
            <FaqList.Item
              title={t(
                "Do I have to pay to attend CEPD.tech e-commerce Academy?"
              )}
            >
              <Paragraph color="nobel" className="text-base leading-8 pb-10">
                {t(
                  `No, we will pay you! You will be paid through contracted employment during the program.`
                )}
              </Paragraph>
            </FaqList.Item>
            <FaqList.Item
              title={t("Will I be paid while participating in the program?")}
            >
              <Paragraph color="nobel" className="text-base leading-8 pb-10">
                {t(
                  `As much as possible! We offer a contract of mandate - the proposed amount will be given during the recruitment interview.`
                )}
              </Paragraph>
            </FaqList.Item>
            <FaqList.Item
              title={t("Is it possible to participate in training remotely?")}
            >
              <Paragraph color="nobel" className="text-base leading-8 pb-10">
                {t(
                  `No, it is extremely important for us that you get to know our organization and have the opportunity to directly contact the CEPD.tech team, so we invite you to our office in the center of Lodz.`
                )}
              </Paragraph>
            </FaqList.Item>
            <FaqList.Item
              title={t(
                "What happens if I don't pass the practice test after 6 weeks?"
              )}
            >
              <Paragraph color="nobel" className="text-base leading-8 pb-10">
                {t(
                  `This is the first stage in the selection of program participants, and passing it determines whether you will remain in the program. Only passing the test guarantees joining the ongoing CEPD.tech projects and implementation to continue working with us!`
                )}
              </Paragraph>
            </FaqList.Item>
            <FaqList.Item
              title={t(
                "Do I have guaranteed employment after completing the program?"
              )}
            >
              <Paragraph color="nobel" className="text-base leading-8 pb-10">
                {t(
                  `Yes, the results of your work and completing the entire program is an invitation to permanent cooperation and joining the CEPD.tech team in implementing IT projects together.`
                )}
              </Paragraph>
            </FaqList.Item>
          </FaqList>
        </div>
      </Section.Container>
    </Section>
  );
};

export default FaqSection;
