import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";

import Section from "components/common/Section/Index";
import Paragraph from "components/common/Paragraph";
import Text from "components/common/Text";

const AboutAcademySection = () => {
  const { t } = useTranslation();
  return (
    <Section id="about" className="relative bg-white">
      <Section.Container className="m-auto px-4 md:px-2 lg:px-4 overflow-x-hidden overflow-hidden flex flex-col justify-center items-center py-12">
        <div className="flex flex-col-reverse md:flex-row lg:px-12 my-12 lg:my-32 gap-12 md:gap-16 lg:gap-24 xl:mx-28">
          <div className="flex flex-col md:w-1/2">
            <StaticImage
              className="rounded-md"
              src="../../../images/academy-photo-001.jpg"
              aspectRatio="1"
              alt="Stock office photo"
              placeholder="none"
            />
          </div>
          <div className="flex flex-col justify-center md:w-1/2">
            <Text
              className="mb-12 font-bold"
              color="cod-gray"
              headlineTag="h3"
              size="4xl"
            >
              {t("Idea")}
            </Text>
            <Paragraph color="cod-gray" className="text-base leading-8">
              {t(
                `During your 3-month internship, you will get access to a training platform and mentorship from experienced developers, as well as compensation for your time spent learning new skills. We will meet in our new office in the center of Lodz to walk through the stages of your development together. If you are thinking about starting a new path in your career, CEPD.tech e-commerce Academy is the option for you!`
              )}
            </Paragraph>
          </div>
        </div>

        <div className="flex flex-col-reverse md:flex-row-reverse lg:px-12 my-20 lg:my-32 gap-12 md:gap-16 lg:gap-24 xl:mx-28">
          <div className="relative flex flex-col md:w-1/2 mt-12 md:mt-24 lg:mt-0 2xl:px-10">
            <StaticImage
              className="rounded-md"
              src="../../../images/academy-photo-002.jpg"
              aspectRatio="1"
              alt="Stock office photo"
              placeholder="none"
            />
            <div
              className="flex sm:hidden lg:flex -top-12 left-12 absolute ml-16 -mr-2 w-6/8 rounded-lg p-4 md:p-6 bg-seashell-peach flex-col lg:flex-row items-start gap-6"
              style={{
                boxShadow: "0px 20px 25px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="md:min-w-9">
                <StaticImage
                  alt="Glasses"
                  aspectRatio="1"
                  height={32}
                  placeholder="none"
                  quality={100}
                  src="../../../images/glasses.svg"
                  width={32}
                />
              </div>
              <Text
                size="xs"
                color="cod-gray"
                className="font-bold leading-relaxed"
              >
                {t(
                  `Did you know that the salary of a Magento Developer is from about PLN 6,000 to PLN 20,000 net per month?`
                )}
              </Text>
            </div>
          </div>
          <div className="flex flex-col justify-center md:w-1/2">
            <Text
              className="mb-12 font-bold"
              color="cod-gray"
              headlineTag="h3"
              size="4xl"
            >
              {t("Why is it worth it?")}
            </Text>
            <Paragraph color="cod-gray" className="text-base leading-8">
              {t(
                `Magento ® is one of the world's most popular platforms for creating e-commerce systems. As an open source technology, it does not require users to pay a license fee, offering extensive modification and configuration options. The Magento platform is an excellent choice for forward-thinking medium and large online stores and small e-commerce sites. In addition, it has a paid variant - the Adobe Commerce version, providing additional solutions.`
              )}
            </Paragraph>
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center gap-12 md:gap-24 bg-gray bg-opacity-10 rounded-2xl mb-16 mt-32 md:mb-24 lg:mx-16 px-8 py-12">
          <div className="flex flex-row items-end -mt-48 md:-mt-40">
            <StaticImage
              width={550}
              src="../../../images/academy-macbook.png"
              alt="Apple Macbook"
              placeholder="none"
              quality={100}
            />
            <StaticImage
              width={210}
              src="../../../images/academy-airpods.png"
              alt="Apple Airpods"
              placeholder="none"
              quality={100}
            />
          </div>
          <div className="w-full">
            <Paragraph color="cod-gray" className="text-base leading-8">
              {t(
                `Our 3-month CEPD.tech e-commerce Academy will prepare you to work as an independent Magento Developer. In addition to the start-up atmosphere, flexibility and working with a team of passionate developers, we provide you with access to the LoD (learning on demand) platform, as well as a Macbook PRO and Airpods. After training, you can choose the form of employment, as well as the model of cooperation - from the office, hybrid or remote.`
              )}
            </Paragraph>
          </div>
        </div>

        <div className="flex flex-col-reverse md:flex-row-reverse lg:px-12 my-20 lg:my-32 gap-12 md:gap-16 lg:gap-24 xl:mx-28">
          <div className="flex flex-col md:w-1/2">
            <StaticImage
              className="rounded-md"
              src="../../../images/academy-photo-003.jpg"
              aspectRatio="1"
              alt="Stock office photo"
              placeholder="none"
            />
          </div>
          <div className="flex flex-col justify-center md:w-1/2">
            <Text
              className="mb-12 font-bold"
              color="cod-gray"
              headlineTag="h3"
              size="4xl"
            >
              {t("For whom?")}
            </Text>
            <Paragraph color="cod-gray" className="text-base leading-8">
              {t(
                `To join CEPD.tech e-commerce Academy, you must have at least basic knowledge of PHP. Your education or previous work experience can be valuable, but the most important thing is your determination and willingness to learn something new!`
              )}{" "}
              {t(
                "The training takes place stationary in our new office at Piramowicza Street in Lodz, Poland."
              )}
            </Paragraph>
          </div>
        </div>

        <div className="flex flex-col-reverse md:flex-row lg:px-12 my-20 lg:my-32 gap-12 md:gap-16 lg:gap-24 xl:mx-28">
          <div className="flex flex-col md:w-1/2">
            <StaticImage
              className="rounded-md"
              src="../../../images/academy-photo-004.jpg"
              aspectRatio="1"
              alt="Stock office photo"
              placeholder="none"
            />
          </div>
          <div className="flex flex-col justify-center md:w-1/2">
            <Text
              className="mb-12 font-bold"
              color="cod-gray"
              headlineTag="h3"
              size="4xl"
            >
              {t("How does the program work?")}
            </Text>
            <Paragraph color="cod-gray" className="text-base leading-8">
              {t(
                `The program is divided into sprints. That's 5 working days, the first 4 of which are your own work with the provided materials, and the last day is a joint workshop. You decide on the amount and pace of the content you absorb yourself, with the help of experienced Magento developers from the CEPD.tech team. After 6 weeks, you and other program participants will take a practice exam verifying your progress.`
              )}
            </Paragraph>
          </div>
        </div>

        <div className="flex flex-col-reverse md:flex-row-reverse lg:px-12 my-20 lg:my-32 gap-12 md:gap-16 lg:gap-24 xl:mx-28">
          <div className="flex flex-col md:w-1/2">
            <StaticImage
              className="rounded-md"
              src="../../../images/academy-photo-005.jpg"
              aspectRatio="1"
              alt="Stock office photo"
              placeholder="none"
            />
          </div>
          <div className="flex flex-col justify-center md:w-1/2">
            <Text
              className="mb-12 font-bold"
              color="cod-gray"
              headlineTag="h3"
              size="4xl"
            >
              {t("What's next?")}
            </Text>
            <Paragraph color="cod-gray" className="text-base leading-8">
              {t(
                `Having passed the test after 6 weeks of work, you will join ongoing IT projects and your induction into CEPD.tech's technical team will begin. After completing the 3-month course cycle, we will give you the opportunity to join our team! You can choose the form of employment, as well as the model of cooperation - from the office, hybrid or remotely. Welcome to the ranks of Magento Developers!`
              )}
            </Paragraph>
          </div>
        </div>
      </Section.Container>
    </Section>
  );
};

export default AboutAcademySection;
