import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Page from "components/Page";
import Layout from "components/Layout";
import colors from "constants/colors";

import RecruitmentProcess from "components/sections/academy/RecruitmentProcess";
import Intro from "components/sections/academy/Intro";
import About from "components/sections/academy/About";
import Join from "components/sections/academy/Join";
import FAQ from "components/sections/academy/FAQ";

const AcademyPage = ({ data }) => {
  const { t } = useTranslation();
  const { title, description } = data?.markdownRemark.frontmatter.page;

  return (
    <Page
      id="academy"
      metadata={{
        title: t(title),
        description: t(description),
      }}
    >
      <Layout
        stickyHeader={false}
        headerClassName="py-9"
        mainClassName="bg-white"
        isCallActionButtonVisible={false}
        palette={{
          background: colors["cod-gray"],
          primary: colors.jaffa,
          secondary: colors.white,
        }}
      >
        <Intro />
        <About />
        <RecruitmentProcess />
        <Join />
        <FAQ />
      </Layout>
    </Page>
  );
};

export default AcademyPage;

export const query = graphql`
  query ($language: String!) {
    markdownRemark(frontmatter: { page: { id: { eq: "academy" } } }) {
      ...PageInformation
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
