import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Button from "components/common/Button";
import Section from "components/common/Section/Index";
import Text from "components/common/Text";

const IntroAcademySection = () => {
  const { t } = useTranslation();
  return (
    <Section
      id="intro"
      className="relative bg-cod-gray round-l-xl round-r-xl overflow-hidden"
    >
      <Section.Container className="m-auto px-4 md:px-2 lg:px-4 lg:py-14 2xl:py-24 overflow-x-hidden overflow-hidden flex flex-col justify-center items-center">
        <div className="absolute top-0 left-0 w-full h-full">
          <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <radialGradient id="introGradient1">
                <stop offset="10%" stopColor="rgba(229, 83, 43, 0.886)" />
                <stop offset="90%" stopColor="rgba(17,17,17,0.0)" />
              </radialGradient>
              <radialGradient id="introGradient2">
                <stop offset="30%" stopColor="rgba(229, 83, 43, 0.886)" />
                <stop offset="160%" stopColor="rgba(17,17,17,0.0)" />
              </radialGradient>
              <radialGradient id="introGradient3">
                <stop offset="10%" stopColor="rgba(229, 83, 43, 0.886)" />
                <stop offset="95%" stopColor="rgba(17,17,17,0.0)" />
              </radialGradient>
              <radialGradient id="introGradient4">
                <stop offset="5%" stopColor="rgba(229, 83, 43, 0.886)" />
                <stop offset="160%" stopColor="rgba(17,17,17,0.0)" />
              </radialGradient>
            </defs>
            <circle
              style={{
                opacity: 0.125,
              }}
              cx="2"
              cy="1.25"
              r="12.75"
              fill="url('#introGradient1')"
            />
            <circle
              style={{
                opacity: 0.115,
              }}
              cx="7"
              cy="4"
              r="3.25"
              fill="url('#introGradient2')"
            />
            <circle
              style={{
                opacity: 0.145,
              }}
              cx="15"
              cy="0"
              r="4"
              fill="url('#introGradient3')"
            />
            <circle
              style={{
                opacity: 0.135,
              }}
              cx="19"
              cy="4"
              r="7"
              fill="url('#introGradient4')"
            />
          </svg>
        </div>

        <div className="absolute right-6 w-16 md:right-32 bottom-32 md:bottom-64 lg:right-0 xl:right-32 xl:w-96 md:width-48 lg:bottom-96 md:w-24 lg:w-32">
          <StaticImage
            width={180}
            src="../../../images/academy-shape-001.svg"
            alt="Shape-001"
            placeholder="none"
            quality={100}
          />
        </div>

        <div className="absolute bottom-0 -right-10 md:right-0 w-24 md:w-48 lg:w-64">
          <StaticImage
            width={250}
            src="../../../images/academy-shape-002.svg"
            alt="Shape-002"
            placeholder="none"
            quality={100}
          />
        </div>

        <div className="absolute bottom-0 -left-10 w-32 md:w-64 lg:w-auto">
          <StaticImage
            width={350}
            src="../../../images/academy-shape-003.svg"
            alt="Shape-003"
            placeholder="none"
            quality={100}
          />
        </div>

        <div className="py-24 3xl:pb-48 3xl:pt-48 relative text-center">
          <StaticImage
            width={64}
            className="mb-8"
            src="../../../images/logo_magento_academy.svg"
            alt="Magento Academy logo"
            placeholder="none"
            quality={100}
          />
          <Text
            color="white"
            className="block mb-5 font-extraBold text-4xl lg:text-5xl"
            size="6xl"
          >
            CEPD.tech
            <br />
            e-commerce
            <br />
            Academy
          </Text>
          <div className="w-64 lg:w-auto">
            <StaticImage
              className="mb-20"
              src="../../../images/academy-line-001.svg"
              alt="Magento CMS logo"
              placeholder="none"
              quality={100}
            />
          </div>
          <Text
            color="nobel"
            className="block mb-16 font-normal leading-relaxed"
            size="xl"
          >
            {t("CEPD.tech e-commerce Academy")} <br />
            {t("is more than just a course.")}
          </Text>
          <Button
            href="https://system.erecruiter.pl/FormTemplates/RecruitmentForm.aspx?WebID=1c8097ede2f54736bf2e02eeb793c052"
            className="py-6 px-14 font-bold"
            style={{
              boxShadow: "0px 0px 42px rgba(245, 125, 60, 0.58)",
            }}
            bgColor="jaffa"
            textSize="xl"
            textColor="white"
            rounded
            link
          >
            {t("Sign up")}
          </Button>
        </div>
      </Section.Container>
    </Section>
  );
};

export default IntroAcademySection;
