import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Section from "components/common/Section/Index";
import Text from "components/common/Text";
import Paragraph from "components/common/Paragraph";
import Button from "components/common/Button";

const JoinAcademySection = () => {
  const { t } = useTranslation();
  return (
    <Section id="join" className="relative bg-white">
      <Section.Container className="px-4 md:px-2 lg:px-12 py-32 lg:py-64 text-center w-full lg:w-2/4 m-auto">
        <Text
          className="mb-12 font-bold"
          color="cod-gray"
          headlineTag="h2"
          size="4xl"
        >
          {t("How to apply?")}
        </Text>
        <Paragraph color="cod-gray" className="text-base leading-8 mb-12">
          {t(
            `To participate in the CEPD.tech e-commerce Academy program, fill out the form available below. We will take care of the rest!`
          )}
        </Paragraph>
        <Button
          href="https://system.erecruiter.pl/FormTemplates/RecruitmentForm.aspx?WebID=1c8097ede2f54736bf2e02eeb793c052"
          className="py-4 px-14 font-bold"
          bgColor="jaffa"
          textSize="xl"
          textColor="white"
          rounded
          link
        >
          {t("Sign up")}
        </Button>
      </Section.Container>
    </Section>
  );
};

export default JoinAcademySection;
