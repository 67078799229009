import React, { useState, isValidElement, cloneElement, Children } from "react";
import PropTypes from "prop-types";

import classNames from "classnames";
import FaqItem from "./Item";

const FaqList = ({ className, children }) => {
  const [activeQuestion, setActiveQuestion] = useState(0);

  const selectItem = (event, onClick, index) => {
    if (onClick) onClick(event, index);
    setActiveQuestion(index);
  };

  return (
    <div className={classNames("Faq w-full", className)}>
      <ul className={classNames("Faq__Items__List flex flex-col")}>
        {Children.map(
          children,
          (child, index) =>
            isValidElement(child) && (
              <li>
                {cloneElement(child, {
                  isActive: activeQuestion === index,
                  onClick: (event) => selectItem(event, child.onClick, index),
                })}
              </li>
            )
        )}
      </ul>
    </div>
  );
};

FaqList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

FaqList.defaultProps = {
  className: "",
};

FaqList.Item = FaqItem;

export default FaqList;
