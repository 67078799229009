import Text from "components/common/Text";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";

import SliderStep from "./SliderStep";

const RecruitmentProcessSlider = ({ steps }) => {
  const { t } = useTranslation();

  const svgHeight = 720;
  const svgWidth = 1512;
  const stepWidth = 350;
  const stepBoxWidth = 265;

  return (
    <div className="relative lg:pt-10 pb-15">
      <div
        style={{ width: `${svgWidth}px` }}
        className="overflow-y-hidden snap-mandatory snap-x h-full max-w-full m-auto lg:mb-24"
      >
        <div
          className="lg:pt-10 flex flex-col justify-end"
          style={{ height: svgHeight, width: `${svgWidth}px` }}
        >
          <div
            className="overflow-hidden rounded-xl"
            style={{ height: "369.5px" }}
          >
            <svg
              width="1512"
              height="225"
              viewBox="0 0 1512 225"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M-23.9475 168.319C-24.2221 168.29 -24.4683 168.489 -24.4972 168.763C-24.5262 169.038 -24.3271 169.284 -24.0525 169.313L-23.9475 168.319ZM-24.0525 169.313C10.1385 172.921 43.2224 184.3 80.961 195.791C118.684 207.277 161.003 218.856 213.576 222.81C318.73 230.718 464.819 208.115 697.221 93.4484L696.779 92.5516C464.477 207.169 318.569 229.703 213.651 221.813C161.188 217.868 118.951 206.313 81.2523 194.834C43.5692 183.36 10.3645 171.94 -23.9475 168.319L-24.0525 169.313ZM697.221 93.4484C813.36 36.1455 904.163 10.0037 979.572 3.00545C1054.97 -3.99211 1115.01 8.14711 1169.62 27.448C1196.94 37.1003 1222.89 48.5438 1248.75 60.2861C1274.6 72.026 1300.35 84.0675 1327.22 94.9002C1380.98 116.57 1439.27 133.417 1512 133.417L1512 132.417C1439.44 132.417 1381.28 115.613 1327.6 93.9727C1300.75 83.1504 1275.03 71.1218 1249.16 59.3756C1223.3 47.6317 1197.31 36.1728 1169.96 26.5052C1115.24 7.1664 1055.05 -5.0038 979.48 2.00973C903.915 9.0225 812.992 35.2123 696.779 92.5516L697.221 93.4484Z"
                fill="white"
              />
            </svg>
            <svg
              style={{ margin: "-222px 0 0" }}
              width="1512"
              height="370"
              viewBox="0 0 1512 370"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M697 92.4939C232.296 321.778 113.006 182.768 -24 168.31V369.5H1512V132.41C1221.41 132.41 1161.7 -136.791 697 92.4939Z"
                fill="url(#paint0_linear_63_8283)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_63_8283"
                  x1="890.5"
                  y1="-140"
                  x2="1147"
                  y2="920.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="white" />
                  <stop offset="0.266389" stopColor="#F26225" stopOpacity="0" />
                  <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>

        <div
          style={{
            width: `${svgWidth}px`,
            height: svgHeight,
            margin: `-${svgHeight}px 0 0`,
          }}
          className="flex flex-row h-full"
        >
          <SliderStep
            width={stepWidth}
            className="snap-center"
            stepIndex={1}
            position={{
              left: `calc((${stepWidth}px - ${stepBoxWidth}px) / 2)`,
              bottom: `145px`,
            }}
            {...{ title: steps[0].title, text: steps[0].text }}
            reversed={false}
          />
          <SliderStep
            width={stepWidth}
            className="snap-center"
            stepIndex={2}
            position={{
              left: `calc((${stepWidth}px - ${stepBoxWidth}px) / 2)`,
              bottom: `200px`,
            }}
            {...{ title: steps[1].title, text: steps[1].text }}
            reversed={false}
          />
          <SliderStep
            width={stepWidth}
            className="snap-center"
            stepIndex={3}
            position={{
              left: `calc((${stepWidth}px - ${stepBoxWidth}px) / 2)`,
              bottom: `350px`,
            }}
            {...{ title: steps[2].title, text: steps[2].text }}
            reversed={false}
          />
          <SliderStep
            width={stepWidth}
            className="snap-center"
            stepIndex={4}
            position={{
              left: `calc((${stepWidth}px - ${stepBoxWidth}px) / 2)`,
              bottom: `275px`,
            }}
            {...{ title: steps[3].title, text: steps[3].text }}
            reversed={false}
          />
        </div>
        <div className="sticky left-0 flex lg:hidden flex-row items-center gap-2 justify-center pt-10 pb-24">
          <StaticImage
            className="rotate-180"
            src="../../../../images/recruitment-swipe-arrows.svg"
            width={20}
            alt="Glyph"
            quality={100}
            placeholder="none"
          />
          <Text color="white" size="sm">
            {`${t("Swipe left/right")}`}
          </Text>
          <StaticImage
            src="../../../../images/recruitment-swipe-arrows.svg"
            width={20}
            alt="Glyph"
            quality={100}
            placeholder="none"
          />
        </div>
      </div>
    </div>
  );
};

export default RecruitmentProcessSlider;
